@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Roboto Slab";
}

body {
  margin: 0;
}

.btnCont {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.btn {
  background: transparent;
  border-radius: 50%;
  border: 2px solid white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.2s;
  z-index: 100;
}

.btn:hover {
  background: white;
  border: 2px solid white;
}

.left {
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.right {
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
}

.canvasCont {
  background: -webkit-radial-gradient(center, #303030, #202020);
  height: 100vh;
}

.special1 {
  height: 100vh;
  background: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-family: "Roboto Slab";
  color: white;
}

.initial {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 40px;
}

.initial > p {
  font-size: 20px;
  text-justify: center;
}

.floatTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  color: white;
  padding: 5px;
}

.floatInteractive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  color: white;
  padding: 10px;
}

.lottieCont {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  text-decoration: none;
  color: orange;
}

.title {
  margin-top: 10px;
  margin-bottom: 0px;
}

.subtitle {
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.mini {
  color: orange;
  font-size: 10.5px;
  margin: 0px;
  opacity: 0.6;
  padding: 0 10px;
}

.mini:last-child {
  margin-bottom: 10px;
}

@media screen and (max-width: 950px) {
  .initial {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
  }

  .initial > p {
    font-size: 10px;
    text-justify: center;
  }

  .btnCont {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
  }

  .floatInteractive {
    margin-bottom: 70px;
  }

  .mini {
    /* width: 100%; */
    max-width: 250px;
    text-align: center;
  }
}
